<template>
  <div class="deny-request-sidebar">
    <div class="deny-request-sidebar--content">
      <CRTextArea
        id="deny-request-reason-text-area"
        ref="deny-request-reason"
        v-model="denyReason"
        label="Deny Request Reason"
        placeholder="Reason for denying the request will be sent to the operator."
        :rows="4"
        auto-grow
      />
    </div>
    <div>
      <CRButton
        id="deny-request-save-btn"
        :loading="loading"
        class="action-btn"
        color="primary"
        text-color="white"
        @click="submit"
      >
        Deny Request
      </CRButton>
    </div>
  </div>
</template>

<script>
import operatorCancel from '@/services/operatorCancel'
import ticket from '@/services/ticket'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import { mapActions } from 'vuex'

export default {
  props: {
    referral: {
      type: Object,
      default: () => {},
    },
    ticketId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      denyReason: '',
      loading: false,
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async submit() {
      this.loading = true
      if (!this.denyReason || this.denyReason === '') {
        this.showAlert({
          type: 'error',
          message: 'Deny Reason Is Required.',
        })
        this.loading = false
        return
      }
      const denyReasonComment = {
        comments: `Deny Reason: ${this.denyReason}`,
        createdAt: new Date().toISOString(),
        createdById: this.currentUser.userId,
      }
      const closeTicketComment = {
        comments: 'Closing Ticket and Denying Cancellation Request',
        createdAt: new Date().toISOString(),
        createdById: this.currentUser.userId,
      }
      await ticket
        .partialUpdate({
          id: this.ticketId,
          payload: {
            sendEmail: false,
            subTickets: [denyReasonComment, closeTicketComment],
          },
        })
        .catch((error) => {
          this.showAlert({
            type: 'error',
            message: 'Issue updating ticket.',
          })
          this.loading = false
          return false
        })
      this.$store.dispatch(
        'app/showAlert',
        { message: 'Ticket updated.' },
        { root: true }
      )
      await operatorCancel.operatorDenyConfirm({
        reservationId: this.referral?.reservationId,
        denyCancellationReason: this.denyReason,
      })
      setTimeout(() => {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: `Assignments for (${event.length}) reservations updated.`,
        })
      })

      EventBus.$emit('refresh-detail')
      EventBus.$emit('refresh-reservation-detail')
      EventBus.$emit('refresh-tickets')
      this.loading = false
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.deny-request-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px 40px 0 40px;
  }
}

.action-btn {
  display: flex;
  position: fixed;
  flex-direction: column;
  font-size: 18px;
  bottom: 0;
  width: 500px !important;
  height: 71px !important;
  padding: 24px 0;
  width: inherit;
  border-radius: 0;
  background: $primary;
  z-index: 3;
}

::v-deep .accent--text {
  color: $blue !important;
}
</style>
